.finishSection {
    text-align: center;
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    animation-name: connectingStation;
    animation-duration: 1.5s;
    margin-top: 68px !important;
    padding-bottom: 420px !important;
}

.finishSection p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 125%;
    margin: 0 0px 0 8px;
}

.finishButtonSection {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    flex-flow: wrap;
}

.finishButton {
    margin-top: 24px !important;
    width: 220px;
    opacity: 1;
}